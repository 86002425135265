.about {
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    color: white;
  }
  
  .about h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .founder {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .founder-details {
    display: flex;
    align-items: center;
  }
  
  .founder-image {
    max-width: 200px; /* Adjust the size as necessary */
    margin-right: 2rem;
  }
  
  .founder-text {
    flex: 1;
    color: black;
  }
  
  .founder-text h2 {
    margin-bottom: 1rem;
  }
  
  .founder-text p {
    font-size: 1.2rem;
    line-height: 1.6;
  }