.home-content {
    display: flex;
    justify-content: flex-start; /* Align content to the start (left) */
    align-items: center; /* Center vertically */
    min-height: 100%; /* Ensure it takes up the full height of the parent */
    padding: 2rem;
  }
  
  .hero {
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    color: white;
    padding: 2rem;
    border-radius: 10px;
    text-align: center; /* Align text to the left */
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .hero p {
    font-size: 1.5rem;
    font-weight: bold;
  }