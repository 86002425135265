body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.main-content {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  padding: 2rem;
  background-image: url('./assets/images/background.jpg');
  /* Adjust the path if needed */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: calc(100vh - 200px);
  /* Adjust this value based on your header and footer height */
}