.footer {
    background-color: #232350;
    color: white;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 1rem;
  }
  
  .footer-section {
    flex: 1;
    margin: 0 1rem;
  }
  
  .footer-section h4 {
    margin-bottom: 1rem;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 0.5rem;
  }
  
  .footer-section ul li a {
    color: white;
    text-decoration: none;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between; 
    width: 100%;
    border-top: 1px solid #444;
    padding-top: 1rem;
    text-align: center;
  }
  
  .footer-bottom p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .social-icons {
    display: flex;
  }
  
  .social-icons a {
    color: white;
    margin-left: 1rem;
  }
  
  .social-icons a i {
    font-size: 1.5rem;
  }