.header {
    background-color: #32325d;
    padding: 1rem 2rem;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header .logo h1 {
    margin: 0;
  }
  
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  nav ul li {
    margin-left: 1rem;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease; /* Optional: smooth transition */
  }
  
  nav ul li a:hover {
    color: gold;
  }