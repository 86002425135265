.contact {
  padding: 2rem;
}

.contact h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: white;
  background-color: #32325d;
}

form {
  max-width: 800px;
  margin: 0 auto;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.form-group label {
  width: 100px; /* Set a fixed width for labels */
  margin-right: 1rem;
  color: black;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  flex: 1;
  padding: 0.5rem;
  font-size: 1rem;
}

textarea {
  resize: vertical;
  height: 100px; /* Set a default height for the textarea */
}

button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #32325d;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #0a2ade;
}