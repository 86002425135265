  .coming-soon {
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    color: white;
    padding: 2rem;
    border-radius: 10px;
    text-align: center; /* Align text to the left */
  }
  
  .coming-soon h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .coming-soon p {
    font-size: 1.5rem;
    font-weight: bold;
  }